
import logo from '../../../assets/images/logo.jpg'
import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { changePassword } from 'src/services/Login/ForgotPassword'
import Tostify, { showToast } from 'src/utils/Tostify'

const generateES6Hash = (data) => {
  const encryptedText = CryptoJS.AES.encrypt(data, process.env.REACT_APP_KEY).toString()
  return encryptedText
}

function ConfirmNewPassword() {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const roleId = location.state?.userId

  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    userId: '',
    newPassword: '',
    confirmPassword: '',
  })

  const [errors, setErrors] = useState({
    new_password: '',
    confirm_password: '',
    same_password: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })

    const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

    switch (name) {
      case 'newPassword':
        setErrors({
          ...errors,
          new_password: passwordPattern.test(value)
            ? ''
            : 'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character',
        })
        break
      case 'confirmPassword':
        setErrors({
          ...errors,
          confirm_password: value === formData.newPassword ? '' : 'Passwords do not match',
          same_password:
            formData.oldPassword === value ? 'New password cannot be the same as old password' : '',
        })
        break
      default:
        break
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const newErrors = { new_password: '', same_password: '' }

    if (!formData.newPassword) {
      newErrors.new_password = 'New Password is required'
    }

    if (!formData.confirmPassword) {
      newErrors.confirm_password = 'Confirm Password is required'
    }
    if (formData.newPassword !== formData.confirmPassword) {
      newErrors.same_password = 'New password and confirm password do not match'
      setFormData('')
    } else {
      setErrors({
        confirm_password: '',
      })
    }

    setErrors(newErrors)

    if (!newErrors.new_password && !newErrors.same_password) {
      formData.newPassword = generateES6Hash(formData.newPassword)
      formData.confirmPassword = generateES6Hash(formData.confirmPassword)
      setLoading(true)

      formData.userID = roleId[0] // !imp uncomment

      // console.log(formData)
      const response = await changePassword(formData) // Call your custom API function here
      // let response = { success: true, message: 'Password changed successfully' }

      setLoading(false)

      if (response.success) {
        localStorage.removeItem('token')

        showToast('Password changed successfully', 'success')

        setFormData({
          newPassword: '',
          confirmPassword: '',
        })

        setTimeout(() => {
          navigate('/login')
        }, 2000)
      } else {
        showToast(response.message, 'error')
      }
    }
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-center align-items-center">
            <img
              style={{ height: '70px', width: 'auto', marginRight: '10px' }}
              src={logo}
              alt="Logo"
            />
            <div className="d-flex flex-column align-items-center">
              <span className="mt-2" style={{ fontWeight: 'bold' }}>
                चिकित्सा शिक्षा एवं अनुसंधान निदेशालय हरियाणा
              </span>
              <span style={{ fontWeight: 'bold' }}>
                Directorate of Medical Education & Research Haryana
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center" style={{ marginTop: '50px' }}>
        <div className="w-50">
          <div className="card mx-4">
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 ">
                    <strong style={{ marginLeft: '10px' }}>Change Password</strong>
                  </div>
                </div>
                <hr />

                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="icon"
                      role="img"
                      aria-hidden="true"
                    >
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M384,200V144a128,128,0,0,0-256,0v56H88V328c0,92.635,75.364,168,168,168s168-75.365,168-168V200ZM160,144a96,96,0,0,1,192,0v56H160ZM392,328c0,74.99-61.01,136-136,136s-136-61.01-136-136V232H392Z"
                        className="ci-primary"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="password"
                    name="newPassword"
                    value={formData.newPassword}
                    onChange={handleChange}
                    className={`form-control ${errors.new_password ? 'is-invalid' : ''}`}
                    placeholder="New Password"
                    autoComplete="new-password"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,10}$"
                    required={true}
                  />

                  {errors.new_password && (
                    <div className="invalid-feedback">{errors.new_password}</div>
                  )}
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="icon"
                      role="img"
                      aria-hidden="true"
                    >
                      <path
                        fill="var(--ci-primary-color, currentColor)"
                        d="M384,200V144a128,128,0,0,0-256,0v56H88V328c0,92.635,75.364,168,168,168s168-75.365,168-168V200ZM160,144a96,96,0,0,1,192,0v56H160ZM392,328c0,74.99-61.01,136-136,136s-136-61.01-136-136V232H392Z"
                        className="ci-primary"
                      ></path>
                    </svg>
                  </span>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    className={`form-control ${errors.confirm_password ? 'is-invalid' : ''}`}
                    placeholder="Confirm Password"
                    autoComplete="new-password"
                    pattern={formData.newPassword}
                    required={true}
                  />
                  {errors.confirm_password && (
                    <div className="invalid-feedback">{errors.confirm_password}</div>
                  )}
                  {errors.samePassword && (
                    <div className="invalid-feedback">{errors.same_password}</div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    className="btn"
                    style={{ backgroundColor: '#3c4b64', color: 'white' }}
                    type="submit"
                    disabled={
                      !formData.newPassword ||
                      formData.newPassword !== formData.confirmPassword ||
                      errors.confirm_password ||
                      errors.new_password ||
                      !location.state ||
                      loading
                    }
                  >
                    {loading ? 'Change Password...' : 'Change Password'}
                  </button>
                </div>
              </form>
            </div>
            <ul style={{ listStyle: 'square', color: 'red', fontSize: '14px' }}>
              <strong>Please note that the password should have:</strong>
              <li> At least 8 characters</li>
              <li>At least one uppercase letter</li>
              <li>At least one lowercase letter</li>
              <li>At least one number</li>
              <li>No White Spaces </li>
              <li>Password should not contain your user Id</li>
              <li>One of the digits should be a special character ( i.e. @,#,$,? etc)</li>
            </ul>
          </div>
        </div>
      </div>
      <Tostify />
    </>
  )
}

export default ConfirmNewPassword

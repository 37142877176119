import React, { useEffect, useState } from 'react';
import { loadingState } from 'src/atom/LoadingState';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { globalStream } from 'src/atom/GlobalStream';
import { verifyEmailOTP, verifyMobileOTP, verifyOTP } from 'src/services/Registration/RegisterationService';
import Toastify, { showToast } from 'src/utils/Tostify';
import LogoHeader from 'src/components/header/LogoHeader';

const OtpScreen = () => {
  const otpObject = {
    id: '',
    mobileOTP: '',
    emailOTP: '',
  };

  const [formData, setFormData] = useState(otpObject);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loader, enableLoader] = useRecoilState(loadingState);
  const [countdownMobile, setCountdownMobile] = useState(60);
  const [countdownEmail, setCountdownEmail] = useState(60);
  const [emailField ,setEmailField] = useState(false)
  const [mobileField ,setMobileField] = useState(false)
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream);
  const [validationErrors, setValidationErrors] = useState({
    mobileOTP: '',
    emailOTP: '',
  });

  const navigate = useNavigate();
  const location = useLocation();
  const id = location.state?.det || {};



  const validateForm = () => {
    let errors = {};
    let isValid = true;
    if (!formData.mobileOTP) {
      errors.mobileOTP = 'Please provide OTP';
      isValid = false;
    }
    if (!formData.emailOTP) {
      errors.emailOTP = 'Please provide OTP';
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    enableLoader(true);
    const isValid = validateForm();
    if (isValid) {
      formData.id = id;
      setIsSubmitting(true);
      const response = await verifyOTP(formData);
      if (response.success) {
        let det = response.body
        navigate('/RegistrationValidation' ,{ state: { det } }) ;
      } else {
        setIsSubmitting(false);
        showToast(response.message);
        console.log("heelo",(response.message))
        if(response.body.emailOTPError === true){
          setEmailField(true)
          setMobileField(true)
        }
        if(response.body.mobileOTPError === true){
          setMobileField(true)
          setEmailField(true)
        }
      }
    }
  };
  const handleResendMobileOTP = async () => {
    setCountdownMobile(60);
    const response = await verifyMobileOTP(id);
    if (response.success) {
      showToast('Otp Send to your Mobile Number')
    } else {
      setIsSubmitting(false);
      showToast('Something went Wrong','error');
      setCountdownMobile(0)
    }
  };

  const handleResendEmailOTP = async() => {
    setCountdownEmail(60);
    const response = await verifyEmailOTP(id);
    if (response.success) {
      showToast('Otp Send to your Email Address')
    } else {
      setIsSubmitting(false);
      showToast('Something went Wrong','error');
      setCountdownEmail(0)
    }
  };







  useEffect(() => {
    let timerMobile = null;
    if (countdownMobile > 0) {
      timerMobile = setTimeout(() => {
        setCountdownMobile(countdownMobile - 1);
      }, 1000);
    }

    let timerEmail = null;
    if (countdownEmail > 0) {
      timerEmail = setTimeout(() => {
        setCountdownEmail(countdownEmail - 1);
      }, 1000);
    }

    return () => {
      clearTimeout(timerMobile);
      clearTimeout(timerEmail);
    };
  }, [countdownMobile, countdownEmail]);

  const handleOTPChange = (e) => {
    const { name, value } = e.target;
    if (/^[0-9]{0,6}$/.test(value) || value === '') {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  return (
    <>
      <div className="card">
      <LogoHeader />
      </div>

      <div
        className="mt-2 d-flex justify-content-center align-items-center"
        style={{ fontWeight: 'bold', fontSize: '25px' }}
      >
       Online Application Form For Admission To ANM-GNM-MPHW(Male) courses 2024-25
      </div>

      <div className="card ms-5 me-5 mt-3">
        <div className="card-body">
          <div className="row">
            <div className="col-12 d-flex justify-content-start">
              <th>Instruction Details</th>
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            <div className="col-sm-12">
              <div className="d-flex flex-column align-items-left">
                <label style={{ color: 'red' }}>
                  <tr>1. OTP has been generated.</tr>
                  <tr>
                    2. A One-Time Password (OTP) has been generated and sent to both your mobile
                    number and email address for authentication purposes.
                  </tr>
                </label>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="form-group mt-3 col-md-7">
                    <label htmlFor="mobileOTP">Mobile OTP</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className={`me-3 form-control ${
                          validationErrors.mobileOTP ? 'is-invalid' : ''
                        }`}
                        id="mobileOTP"
                        placeholder="Enter Mobile OTP"
                        name="mobileOTP"
                        value={formData.mobileOTP}
                        onChange={handleOTPChange}
                        disabled={emailField}
                        
                      />
                      <button
                        type="button"
                        onClick={handleResendMobileOTP}
                        disabled={countdownMobile > 0}
                        style={{
                          backgroundColor: 'blue',
                          color: 'white',
                          border: 'none',
                          padding: '0.5rem 1rem',
                          borderRadius: '0.25rem',
                        }}
                      >
                        Resend {countdownMobile > 0 && `(${countdownMobile}s)`}
                      </button>
                    </div>
                    {validationErrors.mobileOTP && (
                      <div className="invalid-feedback">{validationErrors.mobileOTP}</div>
                    )}
                  </div>

                  <div className="form-group mt-3 col-md-7">
                    <label htmlFor="emailOTP">Email OTP</label>
                    <div className="input-group">
                      <input
                        type="text"
                        className={`me-3 form-control ${
                          validationErrors.emailOTP ? 'is-invalid' : ''
                        }`}
                        id="emailOTP"
                        placeholder="Enter Email OTP"
                        name="emailOTP"
                        value={formData.emailOTP}
                        onChange={handleOTPChange}
                        disabled={mobileField === true}
                      />
                      <button
                        type="button"
                        onClick={handleResendEmailOTP}
                        disabled={countdownEmail > 0}
                        style={{
                          backgroundColor: 'blue',
                          color: 'white',
                          border: 'none',
                          padding: '0.5rem 1rem',
                          borderRadius: '0.25rem',
                        }}
                      >
                        Resend {countdownEmail > 0 && `(${countdownEmail}s)`}
                      </button>
                    </div>
                    {validationErrors.emailOTP && (
                      <div className="invalid-feedback">{validationErrors.emailOTP}</div>
                    )}
                  </div>

                  <div className="form-group mt-3 col-md-7 text-center">
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{
                        padding: '0.5rem 1rem',
                        borderRadius: '0.25rem',
                      }}
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toastify />
    </>
  );
};

export default OtpScreen;
import React, { useEffect, useState } from 'react'
import { loadingState } from 'src/atom/LoadingState'
import { useRecoilState } from 'recoil'
import { Link, useLocation } from 'react-router-dom'

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LogoHeader from 'src/components/header/LogoHeader'

import { verifyUserID } from 'src/services/Login/ForgotPassword'

const ForgotPassword = () => {
  const [showResendButton, setShowResendButton] = useState(false)
  const [loader, enableLoader] = useRecoilState(loadingState)
  const [countdown, setCountdown] = useState(120)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = location.state || {}

  const handleCheckChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const userObject = {
    userId: '',
  }
  const [formData, setFormData] = useState(userObject)
  const [success, setSuccess] = useState('')
  const [validationErrors, setValidationErrors] = useState({
    userId: '',
  })


  const handleUserIdOnchange = (event) => {
    const value = event.target.value
    const alphanumericRegex = /^[a-zA-Z0-9]+$/

    if (alphanumericRegex.test(value)) {
      // Update the state or perform other actions with the valid input value
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: value,
      }))
    } else {
      // Remove any non-alphanumeric characters from the input value
      const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '')
      setFormData((prevFormData) => ({
        ...prevFormData,
        userId: sanitizedValue,
      }))
    }
  }
  const handleProceed = async () => {
    enableLoader(true)
    setIsSubmitting(true)
    try {
      const response = await verifyUserID(formData.userId)
      console.log(formData.userId)
      if (response.success === true) {
        const bodyValues = Object.values(response.body)

        navigate('/validatePassword', {
          state: { token: bodyValues, userId: formData.userId },
        })
        // setTimeout(async () => {
        //   await navigate('/validatePassword', {
        //     state: { token: bodyValues, userId: formData.userId },
        //   })
        // }, 1000)
      } else {
        // alert(response.message)

        toast.error(response.message, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        })
      }
    } catch (error) {
      // alert(error.message)
      toast.error(error.message, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      })
    } finally {
      enableLoader(false)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    let timer = null
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else {
      setShowResendButton(true)
    }
    return () => clearTimeout(timer)
  }, [countdown])
  return (
    <>
      <div className="card">
      <LogoHeader />
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}
      >
        <div style={{ maxWidth: '5000px', width: '600px' }}>
          {/* Adjust the max-width to your desired size */}
          <div className="card ms-5 me-5 mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-12 ">
                  <strong style={{ marginLeft: '10px' }}>Retrieve Password</strong>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="d-flex flex-column align-items-left">
                    <label style={{ color: 'red', marginBottom: '1rem' }}>
                      <span>Enter User ID to receive OTP </span>
                    </label>
                  </div>
                  <div className="row justify-content-center">
                    <div className="row justify-content-center">
                      <span className="text-danger">{success}</span>
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <input
                          type="text"
                          className={`form-control ${validationErrors.userId ? 'is-invalid' : ''}`}
                          id="userId"
                          maxLength="10"
                          pattern="^(?!$)[a-zA-Z0-9_]+$"
                          placeholder="Enter UserId"
                          value={formData.userId}
                          onChange={handleUserIdOnchange}
                        />
                        {validationErrors.userId && (
                          <div className="invalid-feedback">{validationErrors.userId}</div>
                        )}
                        <button
                          className="ms-2 btn btn-primary"
                          type="button"
                          onClick={handleProceed}
                          disabled={!formData.userId || isSubmitting}
                        >
                          {isSubmitting ? 'Proceed...' : 'Proceed'}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="toolbar mt-2">
                    <span>
                      <Link to="/login">Back to Login</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`.bg-light {
  /* Set background color */
  background-color: #f8f9fa;
}

/* Center align the container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}






.custom-box{
  width:35rem;
  height :20rem;
  position:relative;
  background-color:#F7F7F7;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);  
}


.widget-main{
  height: calc(100% - 15%);
  background-color:grey;  
  padding: 1rem 2.5rem ;
}

.password-heading{
  display:flex;
  justify-content:start;
  align-items:centre;

}



a{
  text-decoration:none;
}
.toolbar{
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 1rem;
}



`}
      </style>
      <ToastContainer />
    </>
  )
}

export default ForgotPassword

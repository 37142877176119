import React from 'react'
import logo from 'src/assets/images/logo.jpg'

const LogoHeader = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center align-items-center">
          <img
            className="img-fluid"
            style={{ height: '70px', width: 'auto', marginRight: '10px' }}
            src={logo}
            alt="Logo"
          />
          <div className="d-flex flex-column align-items-center">
            <span className="mt-2" style={{ fontWeight: 'bold' }}>
            हरियाणा नर्सेज और नर्सेज-मिडवाइव्स काउंसिल
            </span>
            <span style={{ fontWeight: 'bold' }}>Haryana Nurses And Nurse-Midwives Council</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoHeader
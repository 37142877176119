export async function verifyUserID(userId) {
  // const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_API}/v1/api/verifyUserID?userId=${userId}`

  const rawResponse = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function verifyOTP(userId, otp) {
  // const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_API}/v1/api/verifiedOTP?otp=${otp}&userId=${userId}`

  const rawResponse = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function changePassword(formData) {
  // const token = localStorage.getItem('token')
  const url = `${process.env.REACT_APP_API}/v1/api/changePassword`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(formData),
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function registerCandidateDetails(formData) {
  const url = `${process.env.REACT_APP_API}/v1/api/register/nursing/candidate`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function fetchCounsellingDrive() {
  const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/get/counselling`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

// /v1/api/verify/otp

export async function verifyOTP(formData) {
  const url = `${process.env.REACT_APP_API}/v1/api/verify/otp`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function verifyEmailOTP(id) {
  const url = `${process.env.REACT_APP_API}/v1/api/resend/emailOTP?id=${id}`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}

export async function verifyMobileOTP(id) {
  const url = `${process.env.REACT_APP_API}/v1/api/resend/mobileOTP?id=${id}`

  const rawResponse = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  
  })

  const content = await rawResponse.json()
  if (rawResponse.status === 200) {
    return { body: content.body, success: true, message: '' }
  } else {
    return { body: [], success: false, message: content.message }
  }
}
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { verifyOTP, verifyUserID } from 'src/services/Login/ForgotPassword'
import { loadingState } from 'src/atom/LoadingState'
import { globalStream } from 'src/atom/GlobalStream'
import LogoHeader from 'src/components/header/LogoHeader'
import Tostify, { showToast } from 'src/utils/Tostify'

const ValidatePassword = () => {
  const [formData, setFormData] = useState({ id: '', mobileOTP: '' })
  const [showResendButton, setShowResendButton] = useState(false)
  const [countdown, setCountdown] = useState(120)
  const [isChecked, setIsChecked] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validationErrors, setValidationErrors] = useState({ mobileOTP: '' })
  const [roleId, setRoleId] = useState('')
  const [token, setToken] = useState('')

  const [loader, enableLoader] = useRecoilState(loadingState)
  const [GSteam, setGlobalSteam] = useRecoilState(globalStream)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.state) {
      setRoleId(location.state.userId || '')
      setToken(location.state.token || '')
    }
  }, [location.state])

  useEffect(() => {
    let timer = null
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000)
    } else {
      setShowResendButton(true)
    }
    return () => clearTimeout(timer)
  }, [countdown])

  const handleResend = async () => {
    if (!roleId) {
      showToast('User ID not available', 'error')
      return
    }
    setShowResendButton(false)
    setCountdown(120)
    try {
      const response = await verifyUserID(roleId)
      const bodyValues = Object.values(response.body)
      setToken(bodyValues)
      if (response.success) {
        showToast('OTP sent Successfully!', 'success')
      } else {
        showToast(response.message || 'Failed to send OTP', 'error')
      }
    } catch (error) {
      showToast(error.message || 'An error occurred', 'error')
    }
  }

  const validateForm = () => {
    let errors = {}
    let isValid = true
    if (!formData.mobileOTP) {
      errors.mobileOTP = 'Please provide OTP'
      isValid = false
    }
    setValidationErrors(errors)
    return isValid
  }

  const verifyOtp = async () => {
    if (!token) {
      showToast('Token not available', 'error')
      return
    }
    enableLoader(true)
    try {
      const response = await verifyOTP(token, formData.mobileOTP)
      if (response.success) {
        navigate('/ConfirmNewPassword', { state: { userId: token } })
      } else {
        showToast(response.message || 'OTP verification failed', 'error')
        setFormData((prev) => ({ ...prev, mobileOTP: '' }))
      }
    } catch (error) {
      showToast(error.message || 'An error occurred while verifying OTP', 'error')
    } finally {
      enableLoader(false)
      setIsChecked(false)
    }
  }

  const handleOTPChange = (e) => {
    const { value } = e.target
    const numericValue = value.replace(/\D/g, '')
    const truncatedValue = numericValue.slice(0, 4)
    setFormData((prev) => ({ ...prev, mobileOTP: truncatedValue || null }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsChecked(true)
    if (validateForm()) {
      verifyOtp()
    }
  }

  return (
    <>
      <div className="card">
      <LogoHeader />
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}
      >
        <div style={{ maxWidth: '5000px', width: '600px' }}>
          <div className="card ms-5 me-5 mt-3">
            <div className="card-body">
              <div className="row">
                <div className="col-12 ">
                  <strong style={{ marginLeft: '10px' }}>Validate Password</strong>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="col-sm-12">
                  <div className="d-flex flex-column align-items-left">
                    <label style={{ color: 'red', marginBottom: '1rem' }}>
                      <span>
                        OTP has been generated and sent to your registered email ID and mobile
                        number
                      </span>
                    </label>
                  </div>
                  <div className="row justify-content-center">
                    <div className="row justify-content-center">
                      <div className="col-12 d-flex justify-content-center align-items-center">
                        <input
                          type="text"
                          className={`form-control ${
                            validationErrors.mobileOTP ? 'is-invalid' : ''
                          }`}
                          id="OTP"
                          maxLength="4"
                          placeholder="One time password"
                          value={formData.mobileOTP || ''}
                          onChange={handleOTPChange}
                        />
                        {validationErrors.mobileOTP && (
                          <div className="invalid-feedback">{validationErrors.mobileOTP}</div>
                        )}
                        <button
                          className="ms-2 btn btn-primary"
                          type="button"
                          onClick={handleSubmit}
                          disabled={loading || !formData.mobileOTP || isChecked}
                        >
                          {loading ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : (
                            'Validate'
                          )}
                        </button>

                        {showResendButton && (
                          <button
                            className="ms-2 btn btn-danger"
                            type="button"
                            onClick={handleResend}
                          >
                            Resend
                          </button>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="toolbar mt-2">
                    <span>
                      <Link to="/login">Back to Login</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`.bg-light {
  background-color: #f8f9fa;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.custom-box{
  width:35rem;
  height :20rem;
  position:relative;
  background-color:#F7F7F7;
  -webkit-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);
box-shadow: 0px 0px 20px 2px rgba(0,0,0,0.75);  
}
.widget-main{
  height: calc(100% - 15%);
  background-color:grey;  
  padding: 1rem 2.5rem ;
}

.password-heading{
  display:flex;
  justify-content:start;
  align-items:centre;
}

a{
  text-decoration:none;
}
.toolbar{
  display:flex;
  justify-content:center;
  align-items:center;
  padding: 1rem;
}`}
      </style>

      <Tostify />
    </>
  )
}

export default ValidatePassword
